<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.installationManagement')"
  >
    <div id="outer-title">{{ $t("i18n.installationManagement") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "Contract",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "number",
          label: "contractNo",
          align: "center",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "projectName",
          label: "projectName",
          align: "left",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "signDate",
          label: "createDate",
          align: "center",
        },
        {
          prop: "status",
          label: "contractStatus",
          align: "center",
          formatter: (row) => {
            return row.status ? t("i18n.finished") : t("i18n.inExecution");
          },
        },
        {
          prop: "elevatorCount",
          label: "numberOfElevator",
          align: "center",
        },
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          type: "input",
          rows: false,
          search: true,
        },
        {
          prop: "projectLeader",
          label: "areaManager",
          type: "select",
          data: [],
          search: true,
          props: { label: "name", value: "id" },
          rows: false,
          // rules: [{ required: true, message: t("i18n.select") }],
        },
      ],
      operates: {
        // 列操作按钮
        width: 80,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "new",
            icon: "el-icon-plus",
            class: "add",
            show: proxy.$defined.btnPermission("新增安装合同"),
            type: "outer",
            method: () => {
              router.push({ path: "/install/contractAdd" });
            },
          },
          {
            id: "1",
            label: "edit",
            icon: "el-icon-edit",
            show: proxy.$defined.btnPermission("编辑安装合同"),
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              router.push({
                path: "/install/contractDetail",
                query: { id: row.id },
              });
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.system.installContractPages(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
      getBillPerson()
    };
    const getBillPerson = async () => {
      let { data } = await proxy.$api.maintenance.getBillPerson();
      state.columns.map((item) => {
        if (item.prop === "projectLeader") {
          item.data = data;
        }
      });
    };

    return {
      ...toRefs(state),
      getBillPerson,
      init,
    };
  },
};
</script>
